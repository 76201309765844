import React from 'react';
import AccommodationSearch from 'components/AccommodationSearch';
import { RenderError } from 'lib/index';
import { themr } from '@friendsofreactjs/react-css-themr';
import { useQuery } from '@apollo/react-hooks';
import { useStore, useIsMobile } from 'hooks/index';
import Query from './home.graphql';

import { Banner } from 'components/layout';
import SignUpBlock from 'components/SignUpBlock';
import OfferCard from 'components/OfferCard';
import ArticleCard from 'components/ArticleCard';
import Offer from 'models/Offer';

import styles from './styles.scss';

interface QueryType {
  entries: {
    heading: string;
    tintBanner: boolean;
    tintGradient1: string;
    tintGradient2: string;
    curveColour: string;
    bannerImage: {
      url: string;
    };
    signupBlock: {
      heading: string;
      subHeading: string;
      checklist: {
        listItem: string;
      }[];
      linkText: string;
      image: {
        url: string;
      };
      cta: {
        heading: string;
        buttonText: string;
      }[];
    }[];
  }[];
  offers: OfferItem[];
  news: NewsListItem[];
  accommodation: Accommodation.PropertyResult[];
};

interface Props {
  theme?: Theme;
}

const Index = ({ theme }: Props) => {
  const { data, loading } = useQuery<QueryType>(Query);
  const accommodationStore = useStore('accommodationStore');
  const [ isMobile ] = useIsMobile(768);

  if (!loading && (!data || !data.entries || !data.entries.length)) return (RenderError("Couldn't load Home content"));

  if (typeof window === 'undefined' && data) {
    accommodationStore.loadCMSAccommodation(data.accommodation);
  }

  if (data && data.entries) {
    const { heading, tintBanner, bannerImage, tintGradient1, tintGradient2, curveColour, signupBlock } = data!.entries[0];
    return (
      <>
        <Banner
          height={isMobile ? 500 : 580}
          image={bannerImage[0].url}
          tint={tintBanner}
          tintColour={{ from: tintGradient1, to: tintGradient2 }}
          curveColour={curveColour}
        />
        <div className="basicContainer">
          <div className={theme!.home}>
            <div dangerouslySetInnerHTML={{ __html: heading }} />
            <AccommodationSearch />
            <div className={theme!.body}>
              {!!signupBlock.length && (
                <SignUpBlock content={signupBlock[0]}/>
              )}
            </div>
          </div>
          <div className={theme!.content}>
            <h3>Special items - MEMBERS ONLY</h3>
            <div className="overflowMobile">
              {data.offers.map((entry: OfferItem) => (
                <OfferCard key={entry.slug} content={new Offer(entry)} />
              ))}
            </div>
            <h3>News &amp; Events</h3>
            <div className="overflowMobile">
              {data.news.map((entry: NewsListItem) => (
                <ArticleCard key={entry.slug} {...entry} />
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
  return null;
};

export default themr('Home', styles)(Index);
