import React from 'react';
import { themr } from '@friendsofreactjs/react-css-themr';
import { Truncate } from 'lib/index';

import Arrow from 'images/icons/more-arrow.svg';

import styles from './styles.scss';

interface Props {
  theme?: Theme;
  title: string;
  subHeading: string;
  bannerImage: {
    url: string;
  }[];
  slug: string;
}

const ArticleCard = ({ theme, title, subHeading, bannerImage, slug }:  Props) => (
  <a href={`/news/${slug}`} className={theme!.articleCard}>
    <div
      data-type="image"
      style={{ backgroundImage: `url(${bannerImage[0].url})`}}
    />
    <div className={theme!.text}>
      <h3>{Truncate(title, 50)}</h3>
      <p>{Truncate(subHeading, 150)}</p>
    </div>
    <button>
      Read more
      <Arrow />
    </button>
  </a>
);

export default themr('ArticleCard', styles)(ArticleCard);
