import React from 'react';
import { observer } from 'mobx-react';
import Location from 'components/FilterInputs/Location';
import Dates from 'components/FilterInputs/Dates';
import { Button } from 'components/layout';
import { useStore } from 'hooks/index';
import './styles.scss';

const AccommodationSearch = () => {
  const accommodationStore = useStore('accommodationStore');
  const {filterQueryString} = accommodationStore;

  const goToAccommodationPage = () => {
    window.location.assign(`/accommodation${filterQueryString}`);
  };

  return (
    <div styleName="accommodationSearch">
      <div styleName="inputs">
        <Location />
        <Dates />
        <div styleName="buttonContainer">
          <Button onClick={goToAccommodationPage}>Search</Button>
        </div>
      </div>
    </div>
  );
};

export default observer(AccommodationSearch);
