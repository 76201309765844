import React from 'react';
import { themr } from '@friendsofreactjs/react-css-themr';

import Tick from 'images/icons/tick.svg';

import styles from './styles.scss';

interface Props {
  theme?: Theme;
  content: {
    heading: string;
    subHeading: string;
    checklist: {
      listItem: string;
    }[];
    linkText: string;
    image: {
      url: string;
    };
    cta: {
      heading: string;
      buttonText: string;
    }[];
  };
}

const SignUpBlock = ({ theme, content }: Props) => {
  const { heading, subHeading, checklist, image } = content;
  return (
    <div className={theme!.signupBlock}>
      <div className={theme!.left}>
        <h3>{heading}</h3>
        <h5>{subHeading}</h5>
        <ul>
          {checklist.map(li => (
            <div key={li.listItem}>
              <li>
                <Tick />
                {li.listItem}
              </li>
              <sub>{li.listItem.includes('*') && '*Terms & Conditions apply.'}</sub>
            </div>
          ))}
        </ul>
        {/* <a href="/signup">
          {linkText}
          <Arrow />
        </a> */}
      </div>
      <div className={theme!.right}>
        <img src={image[0].url} />
        {/* <div className={theme!.cta}>
          <h4>{cta[0].heading}</h4>
          <Button link="/signup">
            {cta[0].buttonText}
          </Button>
        </div> */}
      </div>
    </div>
  );
};

export default themr('SignUpBlock', styles)(SignUpBlock);
