import React from 'react';
import cc from 'classcat';
import { format } from 'date-fns';
import { themr } from '@friendsofreactjs/react-css-themr';

import MapPin from 'images/icons/map-pin.svg';

import styles from './styles.scss';

interface Props {
  content: Offer;
  theme?: Theme;
  small?: boolean;
}

const OfferCard = ({ content, theme, small }: Props) => {
  const { offerCategory, title, bannerImage, offerShorttext, offerPriceDiscount, expiryDate } = content;

  function goToCategory(event: React.MouseEvent, slug: string) {
    event.stopPropagation();
    window.location.assign(`/offers/${slug}`);
  }

  return (
    <a
      className={cc({
        [theme!.offerCard]: true,
        [theme!.small]: small
      })}
      href={`/offers/${offerCategory[0].slug}/${content.slug}`}
    >
      <div className={theme!.category} style={{ background: offerCategory[0].tintGradient1 }}>
        <button onClick={(e) => goToCategory(e, offerCategory[0].slug)} style={{ color: offerCategory[0].tintGradient2 }}>
          {offerCategory[0].title}
        </button>
      </div>
      <img src={bannerImage[0].url} />
      <div className={theme!.body}>
        <h4>{title}</h4>
        <p>{offerShorttext}</p>
        <div className={theme!.bottom}>
          <div className={theme!.price}>
            <span>Save</span>
            <h4 styleName={cc({isNaN: isNaN(parseInt(offerPriceDiscount[0]))})}>{offerPriceDiscount}</h4>
          </div>
          <div className={theme!.location}>
            {expiryDate && (
              <div className={theme!.expiry}>
              Offer ends {format(new Date(expiryDate), 'dd/MM/yy')}
              </div>
            )}
            {content.shortLocation && (
              <div>
                <span>
                  <MapPin />
                  {content.shortLocation}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </a>
  );};

export default themr('OfferCard', styles)(OfferCard);
